.tooltip {
  position: absolute;
  right: 0;
  top: var(--padding);
}

.tooltipAnchor {
  display: block;
  font-family: Oxygen, SourceSansPro, sans-serif;
  font-weight: bold;
  color: var(--black);
  background-color: var(--white);
  border: 1px solid var(--red);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.tooltipPopup {
  display: block;
  width: 200px;
  font-size: 14px;
  text-align: left;
}
