.toolbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 var(--control-group-padding);
  display: flex;
  background-color: var(--gray-bg-light);
  z-index: 100;
}

.toolbarBlock {
  display: flex;
  flex-direction: column;
  color: var(--gray-text-darker);
  padding-top: var(--padding);
  padding-bottom: var(--padding);
}

.toolbarBlockSmall {
  flex-basis: 40%;
}

.toolbarBlockLarge {
  flex-basis: 60%;
}

.toolbarBlock:not(:last-child) {
  margin-right: var(--control-group-padding);
}

.toolbarTitle {
  font-size: 14px;
  height: var(--padding);
  font-weight: bold;
  margin-bottom: var(--padding);
}

.toolbarControls {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 6px;
}

.toolbarControlsTop {
  align-items: normal;
}

.toolbarControlsBottom {
  align-items: flex-end;
  align-self: flex-end;
}

.toolbarTitle + .toolbarControls {
  margin-top: auto;
}

.toolbarControl:not(:last-child) {
  margin-right: var(--control-padding);
}

.toolbarControlXSmall {
  width: 20%;
  max-width: 140px;
}

.toolbarControlSmall {
  width: 35%;
}

.toolbarControlMedium {
  width: 50%;
}

.toolbarControlLarge {
  width: 40%;
}

.toolbarControlXLarge {
  width: calc(60% - var(--padding));
}
