.root {
}

.fullHeightRoot {
  height: 100%;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: calc(var(--padding) * 2);
}

.control {
  margin-bottom: 20px;
}

.button {
  height: 100%;
}

.button:only-child {
  flex: 1;
}

.button:not(:first-child) {
  margin-left: 10px;
}
