/* stylelint-disable */
@font-face {
  font-family: 'Oxygen';
  src: url('../assets/fonts/Oxygen-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url('../assets/fonts/Oxygen-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen';
  src: url('../assets/fonts/Oxygen-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('../assets/fonts/SourceSansPro-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
