html {
  font-size: 16px;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

a,
button,
div,
span {
  transition:
    color 0.05s linear,
    background 0.2s linear;
}

body {
  font-family: SourceSansPro, sans-serif;
  font-size: 14px;
  line-height: 1.15 !important;
  font-weight: 400;
  font-style: normal;
  overflow-y: auto;
}

a {
  text-decoration: none;
  color: inherit;
}
