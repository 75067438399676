:root {
  --black-000: #000;
  --black-100: #222;
  --black-200: #333;
  --black-300: #555;

  --gray-100: #555;
  --gray-150: #666;
  --gray-200: #888;
  --gray-300: #959595;
  --gray-400: #c4c7ce;
  --gray-500: #dfe3e9;
  --gray-600: #c4c4c4;
  --gray-700: #d4d4d4;
  --gray-800: #e3e3e3;

  --white-500: #eaeaea;
  --white-900: #fff;

  --blue-gray-100: #333b46;
  --blue-gray-200: #434a55;
  --blue-gray-300: #4a515c;

  --red-100: #a00000;
  --red-200: #cd0f21;
  --red-300: #ef1a2f;
  --red-400: #ec2539;
  --red-500: #e5293c;
  --red-700: #eb5757;
  --red-800: #e36f6f;
  --red-900: #f7bfc5;
  --red-930: #9b1469;
  --red-950: #ffdfdf;

  --yellow-500: #dba804;
  --yellow-950: #fffbdf;

  --green-200: #0f7d21;
  --green-500: #38bd09;
  --green-700: #56c568;

  --blue-600: #468afe;
  --blue-700: #3fa2f7;

  --black-transparent: rgba(51, 51, 51, 0.5);
  --gray-transparent: rgba(245, 245, 245, 0.7);
  --red-transparent: rgba(229, 41, 60, 0.5);
}
