:root {
  /* colors */
  --black: var(--black-200);
  --gray: var(--gray-300);
  --white: var(--white-900);

  --red-darkest: var(--red-100);
  --red-dark: var(--red-930);
  --red: var(--red-500);
  --red-light: var(--red-700);
  --red-lightest: var(--red-900);

  --yellow: var(--yellow-500);

  --green-dark: var(--green-200);
  --green: var(--green-500);
  --green-light: var(--green-700);

  --blue-light: var(--blue-600);
  --blue-lightest: var(--blue-700);

  /* toasts */
  --error: var(--red-light);
  --info: var(--blue-light);
  --success: var(--green-light);

  /* controls */
  --multi-select-item-bg: var(--red-lightest);

  /* table */
  --selected-row: var(--yellow-950);
  --error-row: var(--red-950);
  --inactive-row: var(--gray-800);
  --inactive-hover-row: var(--gray-700);

  /* background */
  --gray-bg-light: #f5f5f5;
  --gray-bg-dark: #333b46;

  /* text */
  --blue-text: var(--blue-lightest);
  --gray-text: var(--gray-150);
  --gray-text-lighter: #797979;
  --gray-text-darker: var(--blue-gray-100);
  --gray-icon: #686b6f;

  /* borders */
  --gray-border-darkest: var(--blue-gray-100);
  --gray-border-darker: var(--gray-200);
  --gray-border-dark: var(--gray-400);
  --gray-border: var(--gray-500);
  --gray-border-light: var(--gray-600);
  --gray-border-lighter: var(--gray-700);
  --gray-border-lightest: var(--white-500);

  /* sizes */
  --header-height: 60px;
  --padding: 15px;
  --control-padding: 16px;
  --control-group-padding: 24px;
  --page-padding: 40px;
  --table-padding: 20px;
  --table-row-height: 44px;
  --table-row-height-small: 34px;
  --border-radius: 4px;

  /* z-indexes */
  --tooltip: 1000;
  --loading: 9999;
}
